import React, { Component } from "react";
import FullSpinner from "./components/FullSpinner/FullSpinner";
import "./Login.scss";
import API from "./lib/API";
import Storage from "./lib/Storage";
import { GoogleLogin } from "react-google-login";
// require('dotenv').config({ path: '../' })

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
      error: false
    };

    this.successResponseGoogle = this.successResponseGoogle.bind(this);
    this.failResponseGoogle = this.failResponseGoogle.bind(this);

    this.performLogin = this.performLogin.bind(this);
    this.successResponseLocal = this.successResponseLocal.bind(this);

    // this.successResponseNormal = this.successResponseNormal.bind(this);
    // this.failResponseNormal = this.failResponseNormal.bind(this);
  }

  async componentDidMount() {
    let user = await Storage.get("user");
    if (!user || user.access_token === "") {
      await Storage.deleteAll();
    } else {
      this.props.history.push("/");
    }
  }

  async performLogin(user) {
    try {
      let u = await API.user.getMe(user.access_token);
      user.timesheetType = 'timesheet'
      for (let k in u) {
        if (user.hasOwnProperty(k)) {
          user[k] = u[k];
        }
      }
      try {
        user.roles = u.roles.split(",");
      } catch (e) {
        user.roles = [];
      }

      await Storage.set("user", user);
      let self = this;
      this.setState(
        {
          access_token: user.access_token
        },
        () => {
          self.props.history.push("/");
        }
      );
    } catch (error) {
      // console.log('Login Error', error);
      this.setState({
        error: error.toString()
      });
    }
  }

  async successResponseLocal(username, password) {
    try {
      let user = await API.userlogin(username, password);
      if (user.error) {
        this.setState({ error: user.error.error.errors });
      } else {
        this.performLogin(user);
      }
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  }

  failResponseGoogle(r) {
    console.log(r)
  }

  async successResponseGoogle(r) {
    try {
      let user = await API.login(r.code);
      this.performLogin(user);
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  }

  render() {
    return (
      <div className="card h-100 justify-content-center">
        <div className="container-fluid container">
          <FullSpinner isLoading={this.state.isLoading}>
            {this.state.error ? <div>{this.state.error}</div> : null}

            <BaseLogin
              onGoogleSuccess={this.successResponseGoogle}
              onLocalSuccess={this.successResponseLocal}
              />
          </FullSpinner>
        </div>
      </div>
    );
  }
}

class BaseLogin extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failResponseGoogle = this.failResponseGoogle.bind(this)

    this.state = {
      username: "",
      password: "",
      isLoading: false,
      error: false,
      clientid: process.env.REACT_APP_GOOGLE_CLIENTID
    };
  }

  handleSubmit(event) {
    //console.log('clientid');
    //console.log(this.state.clientid)
    event.preventDefault();
    this.props.onLocalSuccess(this.state.username, this.state.password);
  }

  failResponseGoogle(r) {
    this.setState({error: r.details})
  }

  render() {
    return (
      <div className="login">
        <div className="panel panel-default col-md-4 mx-auto">
          <div className="panel-heading">
            <center>
              <h3 className="panel-title">Weekie login</h3>
            </center>
          </div>

          <div className="panel-body">
            <fieldset>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    id="exampleFormControlInput1"
                    placeholder="yourmail@example.com"
                    label="Email address"
                    data-validate="Email address is required"
                    value={this.state.username}
                    onChange={e => {
                      this.setState({ username: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    type="password"
                    value={this.state.password}
                    onChange={e => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group">
                  <input
                    className="btn btn-lg btn-success btn-block"
                    type="submit"
                    value="Login"
                  />
                </div>
              </form>
            </fieldset>
            {
              this.state.error === false
              ? null
              : <div className="login-error"><b>Google Login error:&nbsp;</b>{this.state.error}</div>
            }
            {           
              process.env.REACT_APP_GOOGLELOGIN === 'true'
              ? <GoogleLogin
                className="btn-block"
                clientId={this.state.clientid}
                buttonText="Login with Google"
                prompt="consent"
                responseType="code"
                accessType="offline"
                onSuccess={this.props.onGoogleSuccess}
                onFailure={this.failResponseGoogle}
                cookiePolicy={"single_host_origin"}
              />
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}
