import React from 'react'
import LayoutPM from './LayoutPM'
import Storage from './lib/Storage'
import DateUtils from './lib/DateUtils'
import BudgetStatus from './components/Budget/BudgetStatus'
import moment from 'moment'
import API from './lib/API'
import { Table, Tooltip } from 'antd'
import './PMHome.css'
export default class PMHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            budgets: [],
            budgetsOnTable: [],
            filters: '',
            oldestMilestones: [],
            sortBy: 'name',
            sortOrder: 'ASC',
            enableGoTo: true
        }
        this.applyFilter = this.applyFilter.bind(this)
        this.goTo = this.goTo.bind(this)
        this.Delete = this.Delete.bind(this)
    }

    async componentDidMount() {
        let user = await Storage.get('user')
        if (!user || user.access_token === '') {
            await Storage.deleteAll()
            this.props.history.push('/login')
        } else {
            let u = await API.budget.getList(user.access_token)
            this.setState({
                budgets: u,
                budgetsOnTable: u
            })
        }
    }

    applyFilter(e) {
        let f = e.target.value
        let budgetF = this.state.budgets.filter((i) => {
            let res = false
            if (f === '') {
                return true
            }
            if (process.env.REACT_APP_SHOWCOOWNER === 'true') {
                if (
                    this.strMatch(i.name, f) ||
                    this.strMatch(i.code, f) ||
                    this.strMatch(i.cName, f) ||
                    this.strMatch(i.coOwnerName, f)
                ) {
                    console.log('yes: ', f)
                    res = true
                }
                return res
            } else {
                if (
                    this.strMatch(i.name, f) ||
                    this.strMatch(i.code, f) ||
                    this.strMatch(i.cName, f) ||
                    this.strMatch(i.oName, f)
                ) {
                    console.log('yes: ', f)
                    res = true
                }
                return res
            }
        })
        this.setState({
            filters: f,
            budgetsOnTable: budgetF
        })
    }

    strMatch(a, b) {
        if (!a) {
            return false
        }
        return a.toUpperCase().indexOf(b.toUpperCase()) >= 0
    }

    goTo(id, status) {
        if (status === 'approved' || status === 'rejected') {
            this.props.history.push('/projects/budgets/' + id)
        } else {
            this.props.history.push('/projects/budgets/' + id + '/head')
        }
    }

    toDate(d) {
        return DateUtils.format(d)
    }

    async Delete(id) {
        let user = await Storage.get('user')
        if (!user || user.access_token === '') {
            await Storage.deleteAll()
            this.props.history.push('/login')
        } else {
            await API.budget.deleteById(user.access_token, id)
            this.props.history.push('/projects/budgets/')
            let u = await API.budget.getList(user.access_token)
            this.setState({
                budgets: u,
                budgetsOnTable: u
            })
            this.setState({
                enableGoTo: true
            })
        }
    }

    render() {
        let col = [
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                align: 'center',
                sorter: (a, b) => (a.code > b.code ? 1 : -1),
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Id/Name',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                sorter: (a, b) => (a.name > b.name ? 1 : -1),

                width: 300,
                ellipsis: {
                    showTitle: false
                },
                render: (name) => (
                    <Tooltip placement="top" title={name}>
                        {name}
                    </Tooltip>
                )
            },
            {
                title: 'Customer',
                dataIndex: 'cName',
                key: 'cName',
                align: 'center',
                sorter: (a, b) => (a.cName > b.cName ? 1 : -1),
                width: 150
            },
            {
                title: 'Owner',
                dataIndex: 'username',
                key: 'username',
                align: 'center',
                sorter: (a, b) => (a.username > b.username ? 1 : -1),
                width: 150
            },
            {
                title: 'From',
                dataIndex: 'dateFrom',
                key: 'dateFrom',
                align: 'center',
                sorter: (a, b) => (a.dateFrom > b.dateFrom ? 1 : -1),
                width: 150,
                render: (dateFrom) => <span>{this.toDate(dateFrom)}</span>
            },
            {
                title: 'To',
                dataIndex: 'dateTo',
                key: 'dateTo',
                align: 'center',
                sorter: (a, b) => (a.dateTo > b.dateTo ? 1 : -1),
                width: 150,
                render: (dateTo) => <span>{this.toDate(dateTo)}</span>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 150,
                sorter: (a, b) => (a.status > b.status ? 1 : -1),
                render: (status, record) => (
                    <BudgetStatus
                        status={status}
                        dateClose={record.dateClose}
                    />
                )
            },

            {
                title: 'Milestone',
                dataIndex: 'openMilestone',
                key: 'openMilestone',
                align: 'center',
                sorter: (a, b) => {
                    let aStr, bStr, order
                    aStr = a[this.state.sortBy]
                        ? a[this.state.sortBy].dueDate
                        : ''
                    bStr = b[this.state.sortBy]
                        ? b[this.state.sortBy].dueDate
                        : ''
                    if (order === 'ASC') {
                        return aStr > bStr ? 1 : -1
                    } else {
                        return bStr > aStr ? 1 : -1
                    }
                },
                width: 150,

                render: (openMilestone) =>
                    openMilestone !== null ? (
                        openMilestone.dueDate !== '' ? (
                            <span>
                                {openMilestone.name} -{' '}
                                {moment(openMilestone.dueDate).format(
                                    'YYYY-MM-DD'
                                )}
                            </span>
                        ) : null
                    ) : null
            },
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                width: 150,
                align: 'center',
                fixed: 'right',
                render: (id, budget) => {
                    return budget.status === 'draft' ? (
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                                this.setState({
                                    enableGoTo: false
                                })
                                this.Delete(budget.id)
                            }}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    ) : null
                }
            }
        ]
        if (process.env.REACT_APP_SHOWCOOWNER === 'true') {
            col.splice(4, 0, {
                title: 'Co-Owner',
                dataIndex: 'coOwnerName',
                key: 'coOwnerName',
                align: 'center',
                sorter: (a, b) => (a.coOwnerName > b.coOwnerName ? 1 : -1),
                width: 150
            })
        }
        if (process.env.REACT_APP_SHOWORG === 'true') {
            col.splice(2, 0, {
                title: 'Org',
                dataIndex: 'oName',
                key: 'oName',
                align: 'center',
                sorter: (a, b) => (a.oName > b.oName ? 1 : -1),
                width: 150
            })
        }

        return (
            <LayoutPM path={this.props.location}>
                <div className="row no-gutters search-row">
                    <div className="col-8 search-col">
                        <input
                            className="form-control"
                            value={this.state.filters}
                            onChange={this.applyFilter}
                            placeholder="search..."
                        />
                    </div>
                    <div className="col-2 offset-2 search-col">
                        <button
                            className="btn btn-info"
                            onClick={() =>
                                this.props.history.push('/projects/budgets/new')
                            }
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-12 p-0">
                        <Table
                            className="mt-3"
                            dataSource={this.state.budgetsOnTable}
                            columns={col}
                            scroll={{ x: 1500 }}
                            sticky={true}
                            pagination={{
                                responsive: true,
                                hideOnSinglePage: true
                            }}
                            onRow={(row) => ({
                                onClick: (e) => {
                                    if (this.state.enableGoTo === true)
                                        this.goTo(row.id, row.status)
                                }
                            })}
                            rowClassName="pm-table-row"
                        />
                    </div>
                </div>
            </LayoutPM>
        )
    }
}
