import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { ConfigProvider } from 'antd'

import Routing from './Routing'

import it from 'antd/lib/locale/it_IT'
import en from 'antd/lib/locale/en_GB'

import 'antd/dist/antd.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.scss'

import * as serviceWorker from './serviceWorker'

require('dotenv').config()

const language =
    navigator
    ? navigator.language
        ? navigator.language.indexOf('it') > -1
        ? 'it'
        : 'en'
        : navigator.userLanguage
        ? navigator.userLanguage.indexOf('it') > -1
        ? 'it'
        : 'en'
        : 'en'
    : 'en';

ReactDOM.render(
    <ConfigProvider locale={language}>
        <Routing />
    </ConfigProvider>,
    document.getElementById('root')
)

serviceWorker.unregister()
