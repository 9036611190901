import { months } from 'moment'

const rp = require('request-promise')

const getPeople = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/domain/users',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getExternalPeople = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/domain/employee?onlyExternal',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getSingleUserById = (token, userId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + userId,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getPMPeople = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/domain/employee?onlyPM',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const addExternal = (token, user) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users',
        method: 'POST',
        body: user,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const updateExternal = (token, id, user) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + id,
        method: 'PUT',
        body: user,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const updatePasswordExternal = (token, id, newpassword) => {
    let url = process.env.REACT_APP_SERVER
    let data = { password: newpassword }
    return rp({
        url: url + '/admin/users/' + id + '/password',
        method: 'PUT',
        body: data,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getEmployee = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/domain/employee',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getRates = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/domain/rates',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const createUserRates = (token, userId, rate) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + userId + '/rates',
        method: 'POST',
        body: rate,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getPMHierarchies = (token, userId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + userId + '/hierarchies',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const updateUserRates = (token, userId, rate) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + userId + '/rates/' + rate.id,
        method: 'PUT',
        body: rate,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const deleteUserRates = (token, userId, rateId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + userId + '/rates/' + rateId,
        method: 'DELETE',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const moveUserToOrg = (token, orgId, childId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/organizations/' + orgId + '/users',
        method: 'PUT',
        body: {
            childs: [childId]
        },
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const addUserToPM = (token, pmId, childId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + pmId + '/hierarchies',
        method: 'PUT',
        body: {
            childs: [childId]
        },
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const deleteUserFromPM = (token, pmId, childId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/users/' + pmId + '/hierarchies',
        method: 'DELETE',
        body: {
            childs: [childId]
        },
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getMilestonesBA = (token, budgetId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/budgets/' + budgetId + '/milestones',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getAllRequests = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/fulfillments/requests',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const rejectOpeningRequest = (token, id) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/fulfillments/reject/' + id,
        method: 'DELETE',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const acceptOpeningRequest = (token, request) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/fulfillments/accept',
        method: 'PUT',
        body: request,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const closeMonth = (token, request) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/admin/fulfillments/close',
        method: 'PUT',
        body: request,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const admin = {
    getPeople: getPeople,
    getEmployee: getEmployee,
    getRates: getRates,
    getExternalPeople: getExternalPeople,
    addExternal: addExternal,
    updateExternal: updateExternal,
    createUserRates: createUserRates,
    updateUserRates: updateUserRates,
    deleteUserRates: deleteUserRates,
    getPMPeople: getPMPeople,
    getPMHierarchies: getPMHierarchies,
    addUserToPM: addUserToPM,
    deleteUserFromPM: deleteUserFromPM,
    getSingleUserById: getSingleUserById,
    updatePasswordExternal: updatePasswordExternal,
    moveUserToOrg: moveUserToOrg,
    getMilestonesBA: getMilestonesBA,
    getAllRequests: getAllRequests,
    rejectOpeningRequest: rejectOpeningRequest,
    acceptOpeningRequest: acceptOpeningRequest,
    closeMonth: closeMonth
}
export default admin
